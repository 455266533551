<template>
  <section id="overview">
    <div class="grid-y content">
      <div class="grid-x details-statistics-container">

        <!-- DÉTAILS -->
        <div class="cell medium-5 details-container">
          <label v-if="statistics && statistics.length" class="card-title">Details: {{ getDate(statistics[0].month, statistics[0].year) }}</label>
          <label v-else class="card-title">Details</label>

          <div v-if="me && maxDocuments" class="progress-bar-container">
            <app-radial-progress
              class="progress"
              :diameter=diameter
              :totalSteps="maxDocuments"
              :completedSteps="completedSteps">
              <router-link v-if="me && me.subscription === 'FREE_TIER'" :to="{ name: 'plans' }" class="plan-link">Basic Plan</router-link>
              <router-link v-if="me && me.subscription === 'PREMIUM_TIER'" :to="{ name: 'plans' }" class="plan-link">Premium Plan</router-link>
            </app-radial-progress>

            <div v-if="me && me.subscription === 'FREE_TIER'" class="upgrade-banner grid-x">
              <label class="cell auto">You want to use async renders with webhooks and your own S3 object storage ?</label>
              <app-button class="warning cell shrink" @click="goToBillingPage">Upgrade now</app-button>
            </div>

            <div v-if="me && me.subscription === 'PREMIUM_TIER'" class="upgrade-banner grid-x">
              <label class="cell auto">If you want more documents or have a custom need.</label>
              <app-button class="warning cell shrink" @click="goToBillingPage">Contact us</app-button>
            </div>
          </div>
        </div>

        <!-- STATISTIQUES -->
        <div class="cell auto statistics-container">
          <label class="card-title">12 months</label>

          <app-table v-if="statistics && statistics.length" :headers="statisticsTableHeader" :data="statistics" lighter>
            <template slot="date" slot-scope="{ data }">
              <p class="table-cell month">{{ getDate(data.month, data.year) }}</p>
            </template>
            <template slot="totalCallCount" slot-scope="{ data }">
              <p class="table-cell total">{{ utils.formatNumber(data.totalCallCount) }}</p>
            </template>
            <template slot="successCount" slot-scope="{ data }">
              <p class="table-cell success">{{ utils.formatNumber(data.successCount) }}</p>
            </template>
            <template slot="failureCount" slot-scope="{ data }">
              <p class="table-cell failed">{{ utils.formatNumber(data.failureCount) }}</p>
            </template>
          </app-table>

          <div v-else class="no-data">
            <noData />
            <p>No data yet</p>
          </div>
        </div>
      </div>

      <!-- LOGS -->
      <div class="cell auto logs-container">
        <div class="logs-header">
          <label class="card-title">Last logs</label>
          <app-button v-if="logs && logs.length" @click="goToLogsPage">See all</app-button>
        </div>

        <logs-table v-if="logs && logs.length" :logsData="logs" :loading="isLoadingForLogs" lighter />

        <div v-else class="no-request">
          <no-request />
          <div>
            <app-button @click="goToDocumentation">Start using API</app-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import logApi from '@/services/api/logs';
import LogsTable from '@/views/logs/LogsTable.vue';
import utils from '@/services/utils/utils';
import noData from '@/assets/img/no-data.svg?inline';
import noRequest from '@/assets/img/no-request.svg?inline';
import dayjs from 'dayjs';

export default {
  name: 'overview',

  components: {
    noData,
    noRequest,
    LogsTable,
  },

  data() {
    return {
      statisticsTableHeader: [
        { label: 'Month', key: 'date', size: 'medium-5' },
        { label: 'Total calls', key: 'totalCallCount' },
        { label: 'Success', key: 'successCount' },
        { label: 'Failed', key: 'failureCount' },
      ],
      me: null,
      statistics: null,
      logs: null,
      isLoadingForStats: false,
      isLoadingForLogs: false,
      utils,
    };
  },

  mounted() {
    this.getStatistics();
    this.getLogs();

    this.me = this.$attrs.me;
  },

  watch: {
    $attrs() {
      this.me = this.$attrs.me;
    },
  },

  computed: {
    maxDocuments() {
      return this.me ? this.me.package.monthlyLimit : 0;
    },
    // Calcule du diamètre de la barre de progression
    diameter() {
      if (this.maxDocuments >= 100000) {
        return 390;
      }

      if (this.maxDocuments >= 10000) {
        return 360;
      }

      return 320;
    },

    // Récupération du nombre d'appels API
    completedSteps() {
      if (this.statistics?.length) {
        return parseInt(this.statistics[0].totalCallCount, 10);
      }

      return 0;
    },
  },

  methods: {
    // Récupération des statistiques
    async getStatistics() {
      this.isLoadingForStats = true;

      try {
        this.statistics = await logApi.getStats();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Impossible to get logs',
          confirmText: 'Ok',
          hasCancel: false,
        });
      } finally {
        this.isLoadingForStats = false;
      }
    },

    // Récupération des 10 derniers logs
    async getLogs() {
      this.isLoadingForLogs = true;

      try {
        const response = await logApi.getLogs(10, 0);
        this.logs = response.data;
      } catch (err) {
        this.$message.show({
          title: 'Error',
          text: 'Impossible to get your logs',
          confirmText: 'Ok',
          hasCancel: false,
        });
      } finally {
        this.isLoadingForLogs = false;
      }
    },

    // Formatage de la date au format moi année (ex : January 2022)
    getDate(month, year) {
      return `${dayjs().month(month - 1).format('MMMM')} ${year}`;
    },

    // Redirection vers la page des facturations
    goToBillingPage() {
      this.$router.push({ name: 'plans' });
    },

    // Redirection vers la page des logs
    goToLogsPage() {
      this.$router.push({ name: 'logs' });
    },

    // Redirection vers la documentation
    goToDocumentation() {
      window.open(process.env.VUE_APP_DOC_URL, '_blank');
    },
  },
};
</script>

<style lang="sass">
#overview

  p
    margin-top: 0

  @include page

  h1
    color: $white
    margin-bottom: 30px

    svg
      margin-right: 10px

  .card-title
    width: 100%
    color: $white
    font-weight: 500
    font-size: 16px
    line-height: 20px
    text-align: left

  .content
    gap: 24px
    justify-content: space-between
    font-family: 'Outfit', sans-serif

    .details-statistics-container
      gap: 24px

    .details-container, .statistics-container, .logs-container
      @include card

    .details-container, .statistics-container
      height: 350px

    .details-container
      min-width: 450px
      display: flex
      align-items: center
      flex-direction: column

      .progress-bar-container
        height: 100%
        display: flex
        align-items: center
        flex-direction: column
        justify-content: space-between

        .progress
          margin: auto

          .plan-link
            margin-top: 12px
            text-decoration: underline
            color: $accent100
            cursor: pointer

      .upgrade-banner
        gap: 30px
        padding: 14px 16px
        border-radius: 4px
        background: rgba(227, 69, 255, 0.1)

        label
          display: flex
          align-items: center
          color: $accent100
          font-weight: 400
          font-size: 12px
          line-height: 15px
          font-family: 'Inter', sans-serif

        .app-button
          font-size: 14px
          font-weight: 500
          line-height: 18px

    .statistics-container
      display: flex
      flex-direction: column
      align-items: center
      overflow-y: auto
      @include scrollbars
      &::-webkit-scrollbar
        width:  8px
        height: 0px
      &::-webkit-scrollbar-thumb
        background: $primary70
        &:active
          background: lighten($primary70, 10%)

      .app-table
        margin: 18px 0 0 0

        .data

          .price
            @include label-bold

          .app-button
            width: 40px
            height: 40px
            margin: 0

        .table-cell
          color: black
          font-weight: 700
          font-size: 12px
          line-height: 15px
          padding: 4px 8px
          border-radius: 32px

        .month
          padding: 0
          color: $white
          font-weight: 400

        .total
          background: $accent100

        .success
          background: $green

        .failed
          background: $warning

      .no-data
        margin: auto

        p
          margin-top: 20px
          text-align: center
          font-weight: 500
          color: $primary40
          font-size: 14px

        svg
          width: 110px

  .logs-container
    min-height: auto

    .logs-header
      display: flex
      align-items: center
      justify-content: space-between

      .card-title
        flex: 1

    .no-request
      position: relative
      margin: 35px 25px
      padding: 30px 40px
      background: $primary95

      div
        display: flex
        justify-content: center
        position: absolute
        height: 42px
        bottom: -21px
        left: 0
        right: 0
</style>
